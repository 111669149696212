@font-face {
  font-family: "Cambay-Bold";
  src: local("Cambay-Bold"),
    url(./assets/fonts/Cambay/Cambay-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Cambay-Regular";
  src: local("Cambay-Regular"),
    url(./assets/fonts/Cambay/Cambay-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(./assets/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
